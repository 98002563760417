export default {
	props: {
		tableData: {
			type: Array,
			default: []
		}
	},
	methods: {
		cellNumberFormat(row, column, cellValue, index) {
			try {
				return cellValue.toFixed(2)
			} catch(e) {
				return cellValue || '0.00'
			}
		}
	}
}