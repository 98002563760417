
	import common from '@/common/mixins/common.js'
	import page from '@/common/mixins/page.js'
	import { mapActions } from 'vuex'
	import Moment from 'moment'
	export default {
		inject: ['adminLayout'],
		mixins: [common, page],
		data() {
			return {
				queryForm: {
					accountId: '',
					campaignId: '',
					date: [
						Moment().startOf('day').add(-7, 'day').format('YYYY-MM-DD'),
						Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
					],
					countryCode: '',
					website: '',
					channel: '',
				},
				tableData: [],
				q: {
					reportDateMin: Moment().startOf('day').add(-7, 'day').format('YYYY-MM-DD'),
					reportDateMax: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					website: ''
				},
				fetchOnCreated: false,
				channels: [{
					label: 'Google',
					value: 'google'
				}, {
					label: 'Facebook',
					value: 'facebook'
				}, {
					label: 'Tiktok',
					value: 'tiktok'
				}, {
					label: 'Organic',
					value: 'organic'
				}, {
					label: 'Others',
					value: 'others'
				}],
				namespace: ''
			}
		},
		created() {
			const {start, end} = this.$route.query
			if (start && end && /\d{4}-\d{2}-\d{2}/.test(start) && /\d{4}-\d{2}-\d{2}/.test(end)) {
				this.queryForm.date = [start, end]
				this.q.reportDateMin = start
				this.q.reportDateMax = end
			}
		},
		methods: {
			searchEvent() {
				this.q = {
					...this.queryForm,
					reportDateMin: this.queryForm.date[0],
					reportDateMax: this.queryForm.date[1]
				}
				this.page.current = 1
				const search = `start=${this.queryForm.date[0]}&end=${this.queryForm.date[1]}`
				history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
				this.getList()
			},
			handleList(content) {
				this.tableData = content
			},
			async getListUrl() {
				if (this.namespace === '') return
				const trigger = this.trigger
				return this.$store.dispatch(`${this.namespace}/getList`, {
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			clickDownload(filename) {
				const startDate = Moment(Number(this.q.startDate)).format('YYYY-MM-DD')
				const endDate = Moment(Number(this.q.endDate)).format('YYYY-MM-DD')
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/exportData`,
					payload: {...this.q},
					title: `${filename}(${this.q.reportDateMin}~${this.q.reportDateMax})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			}
		}
	}
