<template>
	<el-table height="auto" :data="tableData" size="mini">
		<el-table-column label="Day" min-width="100" align="center">
			<template slot-scope="scope">
				{{scope.row.reportDate | formatDate('YYYY-MM-DD', false)}}
			</template>
		</el-table-column>
		<el-table-column label="Site" prop="website" min-width="100" align="center">
		</el-table-column>
		<el-table-column label="Channel" prop="channel" min-width="100" align="center">
		</el-table-column>
		<el-table-column label="Campaign Name" prop="campaignName" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="Campaign ID" prop="campaignId" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="1d_cost" prop="costL1" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L3d_cost" prop="costL3" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L7d_cost" prop="costL7" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="1d_install" prop="installL1" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="L3d_install" prop="installL3" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="L7d_install" prop="installL7" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="1d_cpi" prop="cpiL1" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L3d_cpi" prop="cpiL3" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L7d_cpi" prop="cpiL7" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="1d_gmv" prop="gmvL1" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L3d_gmv" prop="gmvL3" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L7d_gmv" prop="gmvL7" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="1d_roi" prop="roiL1" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L3d_roi" prop="roiL3" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="L7d_roi" prop="roiL7" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="1d_newpay" prop="newUserL1" min-width="150" align="center">
		</el-table-column>
		<el-table-column label="3d_est_gmv" prop="estGmvL3" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
		<el-table-column label="7d_est_gmv" prop="estGmvL7" min-width="150" align="center" :formatter="cellNumberFormat">
		</el-table-column>
	</el-table>
</template>

<script>
	import TableCellData from './minixs/table-cell-data.js'
	export default {
		mixins: [TableCellData]
	}
</script>
